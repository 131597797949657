import React, { useEffect, useMemo, useState } from 'react'

/**
 * Components
 */
import { Button, Checkbox, Grid, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles';
import LoadingScreen from '../../_components/LoadingScreen'
import FileAccessModal, { FileAccessModalValues } from './_components/FileAccessModal'
import FileContext from './_components/FileContext'
import FileFormModal from './_components/FileFormModal'
import FileMoveModal from './_components/FileMoveModal'
import FolderCard from './_components/FolderCard'
import FolderFormModal from './_components/FolderFormModal'
import UploadModal from './_components/UploadModal'

/**
 * Utils
 */
import Swal from 'sweetalert2'
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, renderWarningButton } from '../../_utils/Helper'

/**
 * Icons
 */
import { CloudUpload, Folder, ArrowForwardIos, Refresh } from '@mui/icons-material'
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete'
import ImagePreview from '../../_components/ImagePreview'
import TagFormModal from './_components/TagFormModal'
import { usePermission } from '../../_providers/PermissionProvider';

export interface DrivePageProps {
}

export interface DriveFile {
    id: number
    folder_id: number
    project_id: number
    filepath: string
    filepath_download: string
    name: string
    is_internal_project_only: 0 | 1
    access_inhouse: 0 | 1
    // access_inhouse_jv: 0 | 1
    // access_digital_sales: 0 | 1
    access_external: 0 | 1
    access_public: 0 | 1
    extension: string
    type: string
    created_at: string
}

export interface DriveFolder {
    id: number
    name: string
    project_id: number
    folder_id: number | null
}

export interface DriveData {
    files: DriveFile[]
    folders: DriveFolder[]
}

interface IAccessDriveURL {
    code: string,
    url: string
}

const DrivePage = (props: DrivePageProps) => {
    const permissions = usePermission()
    const classes = useStyles()

    const [isLoading, setIsLoading] = useState(false)

    const [selectedFiles, setSelectedFiles] = useState<(string | number)[]>([])

    const [tagState, setTagState] = useState<{ cluster_id: string, cluster_label: string }>({
        cluster_id: '',
        cluster_label: '',
    })

    const [clusterTagState, setClusterTagState] = useState<{ cluster_id: string, cluster_name: string, taggable: boolean }>({
        cluster_id: '',
        cluster_name: '',
        taggable: false,
    })

    const [newFolderState, setNewFolderState] = useState<{ name: string, id: null | number }>({
        name: '',
        id: null,
    })
    const [newFolderError, setNewFolderError] = useState({
        name: '',
    })

    const [newFileState, setNewFileState] = useState<File[]>([])
    const [editFileState, setEditFileState] = useState<{ name: string, id: null | number }>({
        name: '',
        id: null,
    })
    const [editFileError, setEditFileError] = useState({
        name: '',
    })

    const [tagForm, setTagForm] = useState(false)

    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)
    const [isNewFolderDialogOpen, setIsNewFolderDialogOpen] = useState(false)

    const [isFileAccessDialogOpen, setIsFileAccessDialogOpen] = useState(false)
    const [isFileMoveDialogOpen, setIsFileMoveDialogOpen] = useState(false)

    const [isEditFileDialogOpen, setIsEditFileDialogOpen] = useState(false)

    const [data, setData] = useState<DriveData>({ files: [], folders: [] })
    const [selectedFolder, setSelectedFolder] = useState<null | DriveFolder[]>(null)

    const [selectedProject, setSelectedProject] = useState<IAutoCompleteOption>({
        id: 0,
        label: '',
    })

    const [imgUrl, setImgUrl] = useState<undefined | string>(undefined)

    useEffect(() => {
        if (permissions) {
            if (permissions['drive.admin']) {
                setSelectedFiles([])
                if (selectedProject.id) {
                    fetchData()
                }
            } else {
                fetchData()
                setSelectedFiles([])
            }
        }
        // eslint-disable-next-line
    }, [selectedFolder, selectedProject, permissions])

    useEffect(() => {
        setSelectedFolder(null)
    }, [selectedProject])

    const fetchData = () => {
        setIsLoading(true)

        const clonedSelectedFolder = selectedFolder ? [...selectedFolder] : []

        DefaultAxios.get(`${process.env.REACT_APP_API_URL}/project-drive`, {
            params: {
                project_id: selectedProject.id,
                folder_id: clonedSelectedFolder.length ? clonedSelectedFolder.pop()?.id : null,
            }
        })
            .then(res => res.data)
            .then(data => {
                setData(data)
                setClusterTagState({
                    cluster_id: data.cluster_id,
                    cluster_name: data.cluster_name,
                    taggable: data.taggable,
                })
                setTagState({
                    cluster_id: data.cluster_id,
                    cluster_label: data.cluster_name
                })
            })
            .catch(err => generalErrorHandler(err))
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleCheckBox = (isChecked: boolean, files: DriveFile[], type: 'all' | number) => {
        setSelectedFiles(prev => {
            if (type === 'all') {
                if (isChecked) {
                    return files.map(file => file.id)
                }

                return []
            } else {
                if (prev.includes(type)) {
                    return prev.filter(file => file !== type)
                }

                return [...prev, type]
            }
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value

        setNewFolderState(prev => ({
            ...prev,
            name: value
        }))

        setNewFolderError(prev => ({
            ...prev,
            name: '',
        }))
    }

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value

        setEditFileState(prev => ({
            ...prev,
            name: value
        }))

        setEditFileError(prev => ({
            ...prev,
            name: '',
        }))
    }

    const getAccessDriveURL = async (folderId: string, name: string): Promise<IAccessDriveURL> => {
        try {
            const res = await DefaultAxios.get(`${process.env.REACT_APP_API_URL}/project-drive/generate-upload-key`, {
                params: {
                    folder_id: folderId,
                    name,
                    project_id: selectedProject.id ? selectedProject.id : null
                },
            })
            return res.data
        } catch (err) {
            throw err
        }
    }



    const onSubmitUpload = async () => {
        if (newFileState.length) {
            setIsUploadDialogOpen(false)
            setIsLoading(true)

            const promises: Promise<void>[] = []
            // let finished = 0
            let failed: string[] = []

            const clonedSelectedFolder = selectedFolder ? [...selectedFolder] : []
            const currentFolderId = clonedSelectedFolder.pop()?.id || 0

            newFileState.forEach(file => {
                promises.push(new Promise<void>((resolve, reject) => {

                    const fileNameArray = file.name.split('.')
                    const fileName = fileNameArray.slice(0, fileNameArray.length - 1).join('.')
                    const userid = localStorage.getItem('user_id') || ''

                    getAccessDriveURL(currentFolderId.toString(), fileName)
                        .then(data => {
                            const myHeaders = new Headers();
                            myHeaders.append("Content-Type", file.type);

                            const requestOptions: RequestInit = {
                                method: "PUT",
                                headers: myHeaders,
                                body: file,
                                redirect: "follow"
                            };

                            fetch(data.url, requestOptions)
                                .then((response) => response.text())
                                .then((result) => {
                                    const payload = {
                                        'user_id': userid,
                                        'type': 'file',
                                        'code': data.code,
                                        'folder_id': currentFolderId.toString(),
                                        'project_id': selectedProject ? selectedProject.id.toString() : null,
                                        'name': fileName
                                    }

                                    return DefaultAxios.post(`${process.env.REACT_APP_API_URL}/project-drive/download-s3`, payload)
                                })
                                .catch(err => {
                                    failed.push(file.name)
                                    resolve()
                                })
                                .finally(() => resolve())
                        })
                        .catch((err) => {
                            failed.push(file.name)
                            resolve()
                        })

                }))
            })

            Promise.all(promises)
                .then(() => {
                    setNewFileState([])

                    setTimeout(() => {
                        fetchData()
                    }, 500)

                    if (failed.length) {
                        Swal.fire({
                            title: "Ada file yang gagal diupload",
                            icon: 'warning',
                            text: failed.reduce((prev, current) => prev + current + '\n', '')
                        })
                    } else {
                        Swal.fire({
                            title: "Berhasil upload semua file",
                            icon: 'success',
                        })
                    }
                })
        }
    }

    const formValidation = () => {
        let isValid = true

        if (newFolderState.name === '') {
            isValid = false
            setNewFolderError(prev => ({ ...prev, name: 'Harap mengisi nama folder' }))
        }

        return isValid
    }

    const onSubmitFolder = () => {
        const isValid = formValidation()

        if (isValid) {
            if (newFolderState.id) {
                setIsNewFolderDialogOpen(false)
                setIsLoading(true)

                const data = {
                    type: 'folder',
                    id: newFolderState.id,
                    name: newFolderState.name,
                }

                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/project-drive/rename`, data)
                    .then(res => res.data)
                    .then(data => {
                        Swal.fire({
                            title: "Berhasil mengubah nama folder",
                            icon: 'success',
                            timer: 1000
                        })
                        setNewFolderState({
                            name: '',
                            id: null,
                        })

                        fetchData()
                    })
                    .catch(err => {
                        setIsNewFolderDialogOpen(true)
                        generalErrorHandler(err)
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            } else {
                setIsNewFolderDialogOpen(false)
                setIsLoading(true)

                const clonedSelectedFolder = selectedFolder ? [...selectedFolder] : []

                const data = {
                    type: 'folder',
                    project_id: selectedProject.id,
                    folder_id: clonedSelectedFolder.length ? clonedSelectedFolder.pop()?.id : null,
                    name: newFolderState.name,
                }

                DefaultAxios.post(`${process.env.REACT_APP_API_URL}/project-drive`, data)
                    .then(res => res.data)
                    .then(data => {
                        Swal.fire({
                            title: "Berhasil membuat folder",
                            icon: 'success',
                            timer: 1000
                        })
                        setNewFolderState({
                            name: '',
                            id: null,
                        })

                        fetchData()
                    })
                    .catch(err => {
                        setIsNewFolderDialogOpen(true)
                        generalErrorHandler(err)
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            }
        }
    }

    const handleEditFolder = (folder: DriveFolder) => {
        setNewFolderState({
            name: folder.name,
            id: folder.id,
        })
        setIsNewFolderDialogOpen(true)
    }

    const handleDeleteFolder = (id: number) => {
        renderWarningButton("Apakah anda yakin ingin menghapus folder ini?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/project-drive/delete`, {
                        type: 'folder',
                        id,
                    })
                        .then(res => res.data)
                        .then(data => {
                            Swal.fire({
                                title: "Berhasil menghapus folder",
                                icon: 'success',
                                timer: 1000
                            })
                            fetchData()
                        })
                        .catch(err => generalErrorHandler(err))
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    const handleDeleteFile = (isMultiple: boolean, id?: number) => {
        renderWarningButton(`Apakah anda yakin ingin menghapus file${isMultiple ? '-file' : ''} ini?`)
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/project-drive/delete`, {
                        type: 'file',
                        id: isMultiple ? selectedFiles : [id]
                    })
                        .then(res => res.data)
                        .then(data => {
                            if (isMultiple) {
                                setSelectedFiles([])
                            }

                            Swal.fire({
                                title: `Berhasil menghapus ${isMultiple ? 'file yang dipilih' : 'file'}`,
                                icon: 'success',
                                timer: 1000
                            })

                            fetchData()
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    const handleChangeAccessFile = (label: 'is_internal_project_only' | 'access_inhouse' | 'access_external' | 'access_public', file: DriveFile) => {
        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/project-drive/update-access`, {
            id: file.id,
            label,
        })
            .then(res => res.data)
            .then(data => {
                Swal.fire({
                    title: `Berhasil mengubah akses file`,
                    icon: 'success',
                    timer: 1000
                })
                fetchData()
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleChangeAccessFileMultiple = (values: FileAccessModalValues) => {
        setIsLoading(true)
        setIsFileAccessDialogOpen(false)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/project-drive/update-access-multiple`, {
            id: selectedFiles,
            ...values,
        })
            .then(res => res.data)
            .then(data => {
                Swal.fire({
                    title: `Berhasil mengubah akses file yang dipilih`,
                    icon: 'success',
                    timer: 1000
                })
                setSelectedFiles([])
                fetchData()
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleFileMove = (folderId: number) => {
        setIsLoading(true)
        setIsFileMoveDialogOpen(false)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/project-drive/move-file`, {
            id: selectedFiles,
            folder_id: folderId === 0 ? 'previous' : folderId,
        })
            .then(res => res.data)
            .then(data => {
                Swal.fire({
                    title: `Berhasil memindahkan file yang dipilih`,
                    icon: 'success',
                    timer: 1000
                })
                setSelectedFiles([])
                fetchData()
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const formValidationFile = () => {
        let isValid = true

        if (editFileState.name === '') {
            isValid = false
            setEditFileError(prev => ({ ...prev, name: 'Harap mengisi nama file' }))
        }

        return isValid
    }

    const handleEditFile = (file: DriveFile) => {
        setEditFileState({
            name: file.name,
            id: file.id,
        })
        setIsEditFileDialogOpen(true)
    }

    const onSubmitEditFile = () => {
        const isValid = formValidationFile()

        if (isValid) {
            setIsEditFileDialogOpen(false)
            setIsLoading(true)

            const data = {
                type: 'file',
                id: editFileState.id,
                name: editFileState.name,
            }

            DefaultAxios.post(`${process.env.REACT_APP_API_URL}/project-drive/rename`, data)
                .then(res => res.data)
                .then(data => {
                    Swal.fire({
                        title: "Berhasil mengubah nama file",
                        icon: 'success',
                        timer: 1000
                    })
                    setEditFileState({
                        name: '',
                        id: null,
                    })

                    fetchData()
                })
                .catch(err => {
                    setIsEditFileDialogOpen(true)
                    generalErrorHandler(err)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const renderFolders = (folders: DriveFolder[]) => {
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <p className={classes.sectionTitle}>
                            Folders
                        </p>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={3}>
                    {
                        folders.map(folder =>
                            <Grid item xs={6} md={3} xl={2}>
                                <FolderCard
                                    {...folder}
                                    onClick={() => setSelectedFolder(prev => prev ? [...prev, folder] : [folder])}
                                    onEdit={handleEditFolder}
                                    onDelete={handleDeleteFolder}
                                />
                            </Grid>
                        )
                    }
                </Grid>
                <br />
                <br />
            </>
        )
    }

    const renderFiles = (files: DriveFile[]) => {
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <p className={classes.sectionTitle}>
                            Files
                        </p>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={3}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            color="primary"
                                            checked={selectedFiles.length >= files.length}
                                            onChange={(e, checked) => handleCheckBox(checked, files, 'all')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        Project Members
                                    </TableCell>
                                    <TableCell>
                                        Inhouse
                                    </TableCell>
                                    {/* <TableCell>
                                        Inhouse JV
                                    </TableCell>
                                    <TableCell>
                                        Digital Sales
                                    </TableCell> */}
                                    <TableCell>
                                        External
                                    </TableCell>
                                    <TableCell>
                                        Public
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    files.map(file =>
                                        <TableRow key={file.id}>
                                            <TableCell>
                                                <Checkbox
                                                    color="primary"
                                                    checked={selectedFiles.includes(file.id)}
                                                    onChange={(e, checked) => handleCheckBox(checked, files, file.id)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <FileContext
                                                    {...file}
                                                    onDelete={handleDeleteFile}
                                                    onEdit={handleEditFile}
                                                    setIsLoading={setIsLoading}
                                                    setImgUrl={setImgUrl}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Switch onChange={() => handleChangeAccessFile('is_internal_project_only', file)} checked={file.is_internal_project_only === 1} />
                                            </TableCell>
                                            <TableCell>
                                                <Switch onChange={() => handleChangeAccessFile('access_inhouse', file)} checked={file.access_inhouse === 1} />
                                            </TableCell>
                                            {/* <TableCell>
                                                <Switch onChange={() => handleChangeAccessFile('access_inhouse_jv', file)} checked={file.access_inhouse_jv === 1} />
                                            </TableCell>
                                            <TableCell>
                                                <Switch onChange={() => handleChangeAccessFile('access_digital_sales', file)} checked={file.access_digital_sales === 1} />
                                            </TableCell> */}
                                            <TableCell>
                                                <Switch onChange={() => handleChangeAccessFile('access_external', file)} checked={file.access_external === 1} />
                                            </TableCell>
                                            <TableCell>
                                                <Switch onChange={() => handleChangeAccessFile('access_public', file)} checked={file.access_public === 1} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </>
        )
    }

    const render = useMemo(() => {
        // let localData = displayedData as (DriveFolder | DriveFolder[])

        return (
            <>
                {data.folders.length ? renderFolders(data.folders) : null}
                {data.files.length ? renderFiles(data.files) : null}
            </>
        )
        // eslint-disable-next-line
    }, [data, selectedFiles])

    const renderNavs = useMemo(() => {
        if (selectedFolder) {
            return (
                <>
                    <span
                        className={classes.navItem}
                        onClick={() => setSelectedFolder(null)}
                    >
                        My Drive
                    </span>
                    {
                        selectedFolder!.map((folder, index) =>
                            <React.Fragment key={index}>
                                <ArrowForwardIos fontSize='small' style={{ margin: '0px 4px -2px' }} />
                                <span
                                    className={index === (selectedFolder.length - 1) ? '' : classes.navItem}
                                    onClick={() => index === (selectedFolder.length - 1) ? undefined : setSelectedFolder(prev => prev ? prev.slice(0, index + 1) : prev)}
                                >
                                    {folder.name}
                                </span>
                            </React.Fragment>
                        )
                    }
                </>
            )
        } else {
            return 'My Drive'
        }
    }, [selectedFolder, classes.navItem])

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        setSelectedProject(value);
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setSelectedProject({ id: 0, label: '' });
    }

    const handleAutocompleteTag = (name: string, value: IAutoCompleteOption) => {
        setTagState(prev => ({
            ...prev,
            cluster_id: value.id.toString(),
            cluster_label: value.label
        }))
    }

    const handleAutocompleteInputChangedTag = (e: any, name: string) => {
        setTagState(prev => ({
            ...prev,
            cluster_id: '',
            cluster_label: ''
        }))
    }

    const onSubmitTag = () => {
        setIsLoading(true)

        const clonedSelectedFolder = selectedFolder ? [...selectedFolder] : []
        const currentFolderId = clonedSelectedFolder.pop()?.id || 0

        const data = {
            id: currentFolderId,
            cluster_id: tagState.cluster_id
        }

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/project-drive/cluster-tagging`, data)
            .then(res => res.data)
            .then(data => {
                Swal.fire({
                    title: "Berhasil Tag Cluster",
                    icon: 'success',
                    timer: 1000
                })

                setTagForm(false)

                setTagState({
                    cluster_id: '',
                    cluster_label: '',
                })

                fetchData()
            })
            .catch(err => {
                generalErrorHandler(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const onRemoveTag = () => {
        renderWarningButton("Apakah anda yakin ingin melepas Tag?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true)

                    const clonedSelectedFolder = selectedFolder ? [...selectedFolder] : []
                    const currentFolderId = clonedSelectedFolder.pop()?.id || 0

                    const data = {
                        id: currentFolderId,
                    }

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/project-drive/remove-tagging`, data)
                        .then(res => res.data)
                        .then(data => {
                            Swal.fire({
                                title: "Berhasil melepas Tag",
                                icon: 'success',
                                timer: 1000
                            })

                            fetchData()
                        })
                        .catch(err => {
                            setIsNewFolderDialogOpen(true)
                            generalErrorHandler(err)
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })

    }

    return (
        <>
            <LoadingScreen fullScreen open={isLoading} />
            <ImagePreview
                image={imgUrl}
                onClose={() => setImgUrl(undefined)}
            />
            <FileFormModal
                isOpen={isEditFileDialogOpen}
                onClose={() => {
                    setIsEditFileDialogOpen(false)
                    setEditFileState({
                        name: '',
                        id: null,
                    })
                }}
                state={editFileState}
                error={editFileError}
                onChange={handleChangeFile}
                onSubmit={onSubmitEditFile}
            />
            <FileMoveModal
                isOpen={isFileMoveDialogOpen}
                onClose={() => setIsFileMoveDialogOpen(false)}
                folders={data.folders}
                isPreviousRoot={!selectedFolder || selectedFolder.length <= 1}
                onSubmit={handleFileMove}
            />
            <UploadModal
                isOpen={isUploadDialogOpen}
                onClose={() => setIsUploadDialogOpen(false)}
                onChange={files => setNewFileState(files)}
                onSubmit={onSubmitUpload}
            />
            <FolderFormModal
                isOpen={isNewFolderDialogOpen}
                onClose={() => {
                    setIsNewFolderDialogOpen(false)
                    setNewFolderState({
                        name: '',
                        id: null,
                    })
                }}
                state={newFolderState}
                error={newFolderError}
                onChange={handleChange}
                onSubmit={onSubmitFolder}
            />
            <FileAccessModal
                isOpen={isFileAccessDialogOpen}
                onClose={() => setIsFileAccessDialogOpen(false)}
                onSubmit={handleChangeAccessFileMultiple}
            />
            <TagFormModal
                isOpen={tagForm}
                onClose={() => {
                    setTagForm(false)
                }}
                state={tagState}
                onSubmit={onSubmitTag}
                onHandleAutocomplete={handleAutocompleteTag}
                onHandleAutocompleteInputChanged={handleAutocompleteInputChangedTag}
            />
            {
                permissions['drive.admin'] &&
                <Grid container spacing={3} className={classes.projectSelection}>
                    <Grid item xs={12} md={4}>
                        <AsyncAutoComplete
                            url={`${process.env.REACT_APP_API_URL}/autocomplete/project`}
                            label="Pilih Project"
                            name="project_id"
                            initialQuery={''}
                            onChange={handleAutocomplete}
                            onInputChange={handleAutocompleteInputChanged}
                            placeholder="Masukan Nama Project"
                        />
                    </Grid>
                </Grid>
            }
            <Grid container spacing={3}>
                <Grid item xs={12} md zeroMinWidth>
                    <h1 className={classes.title}>
                        {renderNavs}
                    </h1>
                </Grid>
                <Grid item xs={12} md='auto'>
                    {
                        selectedFiles.length ?
                            <>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    style={{
                                        marginRight: 24
                                    }}
                                    onClick={() => setIsFileAccessDialogOpen(true)}
                                >
                                    Change Access
                                </Button>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    style={{
                                        marginRight: 24
                                    }}
                                    onClick={() => setIsFileMoveDialogOpen(true)}
                                >
                                    Move
                                </Button>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    style={{
                                        marginRight: 24
                                    }}
                                    onClick={() => handleDeleteFile(true)}
                                >
                                    Delete
                                </Button>
                            </>
                            :
                            <>
                                {
                                    selectedFolder &&
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        startIcon={<CloudUpload />}
                                        style={{
                                            marginRight: 24
                                        }}
                                        onClick={() => setIsUploadDialogOpen(true)}
                                    >
                                        Add File
                                    </Button>
                                }
                                {
                                    !(permissions['drive.admin'] && selectedProject.id === 0 && data.files.length === 0 && data.folders.length === 0) &&
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        startIcon={<Folder />}
                                        onClick={() => setIsNewFolderDialogOpen(true)}
                                    >
                                        Add Folder
                                    </Button>
                                }
                                {
                                    (selectedFolder && selectedProject.id) ?
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            style={{
                                                marginLeft: 24
                                            }}
                                            onClick={() => fetchData()}
                                            startIcon={<Refresh />}
                                        >
                                            Refresh
                                        </Button> :
                                        null
                                }
                            </>
                    }
                </Grid>
            </Grid>
            <hr />
            <br />
            {
                (selectedFolder && clusterTagState.taggable) &&
                <Grid container spacing={3} style={{ textAlign: 'right', alignItems: 'center' }}>
                    <Grid item xs={12} md zeroMinWidth>
                        Cluster: {clusterTagState.cluster_name ? clusterTagState.cluster_name : '-'}
                    </Grid>
                    {
                        clusterTagState.cluster_id ?
                            <>
                                <Grid item xs={12} md='auto'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={onRemoveTag}
                                    >
                                        Remove
                                    </Button>

                                </Grid>
                                <Grid item xs={12} md='auto'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={() => setTagForm(true)}
                                    >
                                        Change
                                    </Button>
                                </Grid>
                            </>
                            : <Grid item xs={12} md='auto'>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setTagForm(true)}
                                >
                                    Tag
                                </Button>

                            </Grid>
                    }
                </Grid>
            }
            {render}
            {
                (permissions['drive.admin'] && selectedProject.id === 0 && data.files.length === 0 && data.folders.length === 0) &&
                <span className={classes.chooseProject}>
                    Harap memilih project terlebih dahulu
                </span>
            }
        </>
    )
}

export default DrivePage

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            margin: 0,
            fontSize: 24,
            color: '#191919',
            fontWeight: 'bold',
            alignItems: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
        },
        sectionTitle: {
            margin: 0,
            fontSize: 14,
            fontWeight: 'bold',
            color: '#191919',
        },
        navItem: {
            cursor: 'pointer',
            transition: '150ms color linear',
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        projectSelection: {
            [theme.breakpoints.down('md')]: {
                marginTop: 2,
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: 16,
            },
        },
        chooseProject: {
            fontSize: 18,
        },
    })
);
