import React, { useState, useReducer, useEffect } from 'react';
import {
    Button,
    Chip,
    Dialog,
    DialogContent,
    Grid
} from '@mui/material';

/**
 * Components
 */
import ModalDetail from '../../_components/_modalDetail/ModalDetail';
import ModalSection from '../../_components/_modalDetail/ModalSection';
import pdf from '../../Assets/Images/drive/pdf.png'
import pdf2 from '../../Assets/Images/png/pdf.png'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { dateFormat, generalErrorHandler, numberToCurrency, renderToastSuccess, renderWarningButton } from '../../_utils/Helper';
import DragAndDropPDF from '../../_components/_formElements/DragAndDropPDF';
import { usePermission } from '../../_providers/PermissionProvider';

interface IProps {
    onSubmitSuccess: () => void,
    accountId: string
    open: boolean
    onClose: Function
}

interface IState {
    name: string,
    phone: string,
    email: string,
    address: string,
    ktp_number: string,
    date_of_birth: string,
    affiliate: number
    affiliate_status: number
    company_name: string
    company_email: string
    salary: string
    affiliate_pdf_url: string
    affiliate_type_label: string
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}


const AccountDetail = (props: IProps) => {
    const permissions = usePermission()
    const [refetch, setRefetch] = useState(false)
    const API_URL = process.env.REACT_APP_API_URL + '/account';
    const [loading, setLoading] = useState(false);
    const [modalPdf, setModalPdf] = useState(false)
    // const [uploadState, setUploadState] = useState({
    //     pdf: [],
    //     pdf_url: ''
    // })

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                phone: '',
                email: '',
                address: '',
                ktp_number: '',
                date_of_birth: '',
                affiliate: 0,
                affiliate_status: 0,
                company_name: '',
                company_email: '',
                salary: '',
                affiliate_pdf_url: '',
                affiliate_type_label: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        name: '',
        phone: '',
        email: '',
        address: '',
        ktp_number: '',
        date_of_birth: '',
        affiliate: 0,
        affiliate_status: 0,
        company_name: '',
        company_email: '',
        salary: '',
        affiliate_pdf_url: '',
        affiliate_type_label: '',
    });

    const loadData = () => {
        setLoading(true);
        DefaultAxios
            .get(`${API_URL}/${props.accountId}`)
            .then(res => {
                const data = res.data;
                setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
            })
            .catch((err) => {
                generalErrorHandler(err);
                props.onClose();
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (props.open) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    const getStatus = () => {
        let label = ''
        switch (inputState.affiliate_status) {
            case 0:
                label = 'Non Affiliate'
                break;
            case 1:
                label = 'Waiting for Verify'
                break;
            case 2:
                label = 'Verified'
                break;
            case 3:
                label = 'Rejected'
        }
        return <Chip label={label} style={{ marginRight: 12 }} />
    }

    const handleAffiliate = (status: string) => {
        renderWarningButton(`Apakah anda yakin ingin ${status.charAt(0).toUpperCase() + status.slice(1)} status Affiliate?`)
            .then((result) => {
                if (result.value) {
                    setLoading(true);
                    DefaultAxios.post(`${API_URL}/${status}`, { id: props.accountId })
                        .then(res => {
                            renderToastSuccess('Status Affiliate berhasil dirubah')
                            loadData()
                            setRefetch(true)
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setLoading(false);
                        })
                }
            })
    }

    const [pdfState, setPdfState] = useState<File[]>([])

    const handleDeletePDF = () => {
        renderWarningButton(`Apakah anda yakin ingin menghapus PDF?`)
            .then((result) => {
                if (result.value) {
                    setLoading(true);
                    DefaultAxios.delete(`${API_URL}/${props.accountId}/delete-affiliate-pdf`)
                        .then(res => {
                            renderToastSuccess('PDF berhasil dihapus')
                            loadData()
                            setRefetch(true)
                            setModalPdf(false)
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setLoading(false);
                        })
                }
            })
    }

    const handleSubmitPDF = () => {
        setLoading(true);
        const fd = new FormData()
        if (pdfState.length) {
            fd.append('affiliate_pdf', pdfState[0])
        }

        DefaultAxios.post(`${API_URL}/${props.accountId}/upload-affiliate-pdf`, fd)
            .then(res => {
                renderToastSuccess('PDF berhasil diupload')
                setModalPdf(false)
                loadData()
                setRefetch(true)
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <>
            <ModalDetail
                open={props.open}
                isLoading={loading}
                onCloseModal={() => { props.onClose(refetch); setRefetch(false) }}
                title={'Account Detail'}
            >
                <>
                    <Dialog
                        open={modalPdf}
                        onClose={() => setModalPdf(false)}
                        maxWidth="sm"
                    >
                        <DialogContent>
                            {
                                !inputState.affiliate_pdf_url ?
                                    <>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <DragAndDropPDF
                                                    onChange={files => setPdfState(files)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <br></br>
                                        <Grid textAlign={'center'}>
                                            <Button variant="contained" color="primary" size="small" onClick={() => handleSubmitPDF()} disabled={!pdfState.length}>
                                                Upload PDF
                                            </Button>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <a href={inputState.affiliate_pdf_url}>
                                                    <img src={pdf2} alt="file icon" width={200} height={200} style={{ cursor: 'pointer' }} />
                                                </a>
                                            </Grid>
                                        </Grid>
                                        <Grid textAlign={'center'}>
                                            <Button variant="contained" color="primary" size="small" onClick={() => handleDeletePDF()}>
                                                Delete PDF
                                            </Button>
                                        </Grid>
                                    </>
                            }
                        </DialogContent>
                    </Dialog>

                    <ModalSection
                        title="Basic Information"
                        xs={12}
                        md={6}
                        content={[
                            ['Name', inputState.name],
                            ['Email', inputState.email],
                            ['Phone', inputState.phone],
                            ['Date Of Birth', dateFormat(inputState.date_of_birth, 'DD-MM-YYYY')],
                            ['KTP Number', inputState.ktp_number],
                            ['Address', inputState.address],
                            ['Salary', inputState.salary ? numberToCurrency(inputState.salary, 'Rp.') : '-'],
                        ]}
                    />

                    <ModalSection
                        title="Affiliate Information"
                        xs={12}
                        md={6}
                        content={[
                            ['Type', inputState.affiliate_type_label],
                            [(
                                <Grid container>
                                    <span style={{ marginRight: 8 }}>Status</span>
                                    {
                                        permissions['account.manage-affiliate'] &&
                                        inputState.affiliate_status === 2 &&
                                        <img onClick={() => setModalPdf(true)} src={pdf} alt="file icon" width={22} height={22} style={{ cursor: 'pointer' }} />
                                    }
                                </Grid>
                            ), (
                                <>
                                    {getStatus()}
                                    {
                                        permissions['account.manage-affiliate'] &&
                                        <>
                                            {
                                                ((inputState.affiliate_status === 1 || inputState.affiliate_status === 3) && (inputState.company_name)) &&
                                                <Button variant="contained" color="success" size="small" onClick={() => { handleAffiliate('approve') }} style={{ marginRight: 12 }}>
                                                    Approve
                                                </Button>
                                            }
                                            {
                                                inputState.affiliate_status === 1 &&
                                                <Button variant="contained" color="primary" size="small" onClick={() => { handleAffiliate('reject') }} style={{ marginRight: 12 }}>
                                                    Reject
                                                </Button>
                                            }
                                            {
                                                (inputState.affiliate_status === 2) &&
                                                <Button variant="contained" color="primary" size="small" onClick={() => { handleAffiliate('revoke') }} style={{ marginRight: 12 }}>
                                                    Revoke
                                                </Button>
                                            }
                                        </>

                                    }
                                </>
                            )],
                            ['Company Name', inputState.company_name],
                            ['Company Email', inputState.company_email]
                        ]}
                    />
                </>
            </ModalDetail>
        </>
    );
}

export default AccountDetail;